.footer_container {
    width: 100%;
    text-align: center;
    background: linear-gradient(
        45deg,
        #573B6E,
        #6F4A6B
    );
}

.footer_container p{
    font-size: 2vh;
    color:aqua;
    font-weight: bolder;
    padding: 2vh 0;
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES (Tablets)) =============*/

@media screen and (max-width: 1024px){
    .footer_container p{
        font-size: 1.9vh;
        padding: 2vh 0;
    }
}