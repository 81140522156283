/* ============= IMPORTS ============ */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

::-webkit-scrollbar {
    display: none;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Poppins', sans-serif;
}

.general_container {
    display: flex;
    background-image: url('./assets/images/backgrounds/1.jpg');
    background-size:cover;
    background-repeat:no-repeat;
    min-height: 87vh;
}

.container {
    width: 50%;
}
