.header_container {
    background: linear-gradient(
        45deg,
        #573B6E,
        #6F4A6B
    );
    width: 100%;
    display: flex;
}

.brand {
    width: 17vh;
    margin: 1vh;
}